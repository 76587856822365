.global-header {
    z-index: 1500;
}

html {
    position: relative;
    min-height: 100%;
}
body {
    margin-bottom:120px;
    /* bottom = footer height */
 
}
footer {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 90px;
    width: 100%;
    overflow:hidden;
}

.btn-outline-primary {
    color:#046b99;
    border-color: #046b99;
}

.btn-outline-primary:hover {
    color:#ffffff;
    background-color: #046b99;
}

.btn-outline-primary:disabled{
    color:#046b99;
    border-color: #046b99;
    background-color: #ffffff;
}

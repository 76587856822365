// May want to research scroll-padding-top more 
html {
  scroll-padding-top: 185px;
}

.headerTopPadding{
  padding-top: 130px;
}

.headerTopEnvPadding{
  padding-top: 170px;
}

.headerTopHomePagePadding{
  padding-top: 90px;
}

.headerTopHomePageEnvPadding{
  padding-top: 100px;
}

.branding .header-organization-banner img {
  padding-right: 15px;
}

.check-icon-checkbox, .check-icon-radio {
  height: 25px;
  width: 25px;
}

.check-icon-checkbox, .check-icon-radio {
  font-size: 15px;
}

.check-icon-checkbox i, .check-icon-checkbox i::before, .check-icon-radio i, .check-icon-radio i::before {
  left: 48%;
}

.check-icon-checkbox, .check-icon-radio {
  left: 10%;
}

.table-hover tbody tr:hover {
    
  background-color: rgb(255, 255, 255);
}

.centered {
  left: 50%;
  text-align: center;
}

.btn-custom-purple-solid,  .btn-custom-purple-solid:active {
  border-color: #ffffff;
  color:  #ffffff;
  background-color: #775dd0;
  font-weight: bold;
}

.btn-custom-purple-solid:hover {
  background-color: #ffffff ;
  border-color: #775dd0;
  color:  #775dd0;
  font-weight: bold;
}

.btn-custom-blue-solid,  .btn-custom-blue-solid:active {
  border-color: #ffffff;
  color:  #ffffff;
  background-color: #52b5f1;
  font-weight: bold;
}

.btn-custom-blue-solid:hover {
  background-color: #ffffff ;
  border-color: #52b5f1;
  color:  #52b5f1;
  font-weight: bold;
}

.btn-custom-green-solid,  .btn-custom-green-solid:active {
  border-color: #ffffff;
  color:  #ffffff;
  background-color: #00e396;
  font-weight: bold;
}

.btn-custom-green-solid:hover {
  background-color: #ffffff ;
  border-color: #00e396;
  color:  #00e396;
  font-weight: bold;
}

.btn-custom-yellow-solid,  .btn-custom-yellow-solid:active {
  border-color: #ffffff;
  color:  #ffffff;
  background-color: #feb019;
  font-weight: bold;
}

.btn-custom-yellow-solid:hover {
  background-color: #ffffff ;
  border-color: #feb019;
  color:  #feb019;
  font-weight: bold;
}

.btn-custom-red-solid,  .btn-custom-red-solid:active {
  border-color: #ffffff;
  color:  #ffffff;
  background-color: #ff4560;
  font-weight: bold;
}

.btn-custom-red-solid:hover {
  background-color: #ffffff ;
  border-color: #ff4560;
  color:  #ff4560;
  font-weight: bold;
}

.btn-custom-grey-solid,  .btn-custom-grey-solid:active {
  border-color: #ffffff;
  color:  #ffffff;
  background-color: #787878;
  font-weight: bold;
}

.btn-custom-grey-solid:hover {
  background-color: #ffffff ;
  border-color: #787878;
  color:  #787878;
  font-weight: bold;
}

.btn-custom-grey-solid:disabled {
  background-color: #ffffff ;
  border-color: #787878;
  color:  #787878;
  font-weight: bold;
}

/* Loading Indicator */
.spinner {
  color: #c8602a;
  font-size: 20px;
  margin: 100px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  // position: relative;
  // position: absolute;
  // top: 40%;
  // left: 50%;
  position: fixed; top: 40%; left: 50%; /* bring your own prefixes */ transform: translate(-50%, -50%); 

  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  // transform: translateZ(0);
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

.spinner-fixed {
  color: #c8602a;
  font-size: 20px;
  margin: 100px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  // position: relative;
  // position: absolute;
  // top: 40%;
  // left: 50%;    
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  // transform: translateZ(0);
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}



/* MEDIA QUERIES */
@media screen and (max-width: 991px) {
  .headerTopPadding{
      padding-top: 150px;
  }  

  .headerTopEnvPadding{
      padding-top: 180px;
  }
}

@media screen and (max-width: 767px) {
  .headerTopPadding{
      padding-top: 120px;
  }   

  .headerTopEnvPadding{
      padding-top: 160px;
  }
 
  .headerTopHomePageEnvPadding{
      padding-top: 130px;
  }
}

@media screen and (max-width: 575px) {

}

@media screen and (max-width: 443px) {
  .headerTopPadding{
      padding-top: 140px;
  }  

  .headerTopEnvPadding{
      padding-top: 190px;
  }
}

@media screen and (max-width: 351px) {
  .headerTopPadding{
      padding-top: 180px;
  }  

  .headerTopEnvPadding{
      padding-top: 220px;
  }
}


@media screen and (max-width: 269px) {
  .headerTopPadding{
      padding-top: 160px;
  }  

  .headerTopEnvPadding{
      padding-top: 210px;
  }
}

@media screen and (max-width: 262px) {
  .headerTopHomePagePadding{
      padding-top: 110px;
  }  

  .headerTopHomePageEnvPadding{
      padding-top: 160px;
  }
}

@media screen and (max-width: 230px) {
  .headerTopPadding{
      padding-top: 200px;
  }  

  .headerTopEnvPadding{
      padding-top: 240px;
  }
}

@media screen and (max-width: 193px) {
  .headerTopPadding{
      padding-top: 220px;
  }  

  .headerTopEnvPadding{
      padding-top: 270px;
  }
}